<template>
  <div class="page-content">
    <!--price table style-1 start-->
    <div class="text-center mt-5">
      <h2 class="title">Voice services</h2>
    </div>

    <section class="bg-contain bg-pos-r pt-0" data-bg-img="images/bg/02.png">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="coming-soon text-center">
              <h1 class="text-uppercase mt-4 mb-2 text-black">
                Coming <span>Soon</span>
              </h1>
              <p class="text-black">
                We Are Creatinig Something Awesome And Exciting For You
              </p>
              <ul
                class="countdown list-inline text-black"
                data-countdown="2020/12/23"
              ></ul>
              <div>
                <h4 class="title z-index-1 mb-4 text-black">
                  Subscribe to get <span> notified!</span>
                </h4>
                <div class="row">
                  <div class="col-md-6 mr-auto ml-auto">
                    <div class="subscribe-form">
                      <form id="mc-form" class="group">
                        <input
                          id="mc-email"
                          type="email"
                          value=""
                          name="EMAIL"
                          class="email box-shadow"
                          placeholder="Email Address"
                          required=""
                        />
                        <input
                          class="btn btn-theme btn-circle"
                          type="submit"
                          name="subscribe"
                          value="Send"
                        />
                        <label for="mc-email" class="subscribe-message"></label>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>
